import React, { useState } from "react"

import Logo from "../../images/abachi/abachi_logo.png"
import githubLogo from "../../images/abachi/github_filled.svg"
import twitterLogo from "../../images/abachi/twitter_circle_filled.svg"
import carboLogo from "../../images/abachi/carbon_logo_discord.svg"
import mailLogo from "../../images/abachi/mail_circle.svg"
import headerBottomBorder from "../../images/abachi/header_bottom_border.png"

import ReactGA from "react-ga"
import classNames from "classnames"


export default function Header() {
  const [toggle, setToggle] = useState(false)
  const toggleCls = classNames('navbar-toggler',
    { 'collapsed': !toggle }
  )
  const mobileMenuWrapperCls = classNames(
    { 'show': toggle }
  )

  const handleToggleMenu = (e) => {
    setToggle(!toggle)
  }
  const navItemClick = () => {
    ReactGA.event({
      category: `Solo Plan LP_Nav Bar_ Contact Us`,
      action: `Solo Plan LP_Nav Bar_ Contact Us`,
    })
  }

  const onLogoClick = () => {
    ReactGA.event({
      category: `Solo Plan LP_logoheader`,
      action: `Solo Plan LP_logoheader`,
    })
  }

  return (
    <div className="top-section">
      <div id='stars'/>
      <div id='stars2'/>
      <div id='stars3'/>
      <div className="container">

        <div className="flex items-center py-6">
          <div className='flex relative w-full'>
            {/*<Link to={`/accounting-software-self-employed-soloplan${queryParam && '?' + queryParam}`} onClick={onLogoClick} className="logo flex items-center sm:justify-start justify-content-center">*/}
              <img src={Logo} alt="logo" className="abachi-logo" />
            {/*</Link>*/}
            <div className="sm:flex hidden menu">
              <a
                className="focus:outline-none flex justify-center items-center"
                href="#"
              >
                White Paper
              </a>
              <span className='mx-3'>
                /
              </span>
              <a
                className="focus:outline-none flex justify-center items-center"
                href="#"
              >
                Pitch Deck
              </a>
              <span className='mx-3'>
                /
              </span>
              {/*<a*/}
              {/*  className="focus:outline-none flex justify-center items-center"*/}
              {/*  href="#"*/}
              {/*>*/}
              {/*  About Us*/}
              {/*</a>*/}
              {/*<span className='mx-3'>*/}
              {/*  /*/}
              {/*</span>*/}
              <a
                className="focus:outline-none flex justify-center items-center web-app"
                href="#"
              >
                Web App
              </a>
              <a
                className="btn-primary coming-soon-btn focus:outline-none flex justify-center items-center ml-3"
                href="#"
              >
                Coming Soon
              </a>
            </div>
            <div className={toggleCls} onClick={handleToggleMenu}>
              <span className="menu_toggle" >
                <span className={`hamburger ${!toggle && 'bg-white'}`}>
                  <span/>
                  <span/>
                  <span/>
                </span>
                <span className="hamburger-cross">
                  <span/>
                  <span/>
                </span>
              </span>
          </div>
            <div id="mobile-menu-wrapper" className={mobileMenuWrapperCls}>
              <ul className="mobile-menu">
                <li>
                  <a
                    className="focus:outline-none flex justify-center items-center"
                    href="#"
                  >
                    White Paper
                  </a>
                </li>
                <li>
                  <span className='mx-3'>/</span>
                </li>
                <li>
                  <a
                    className="focus:outline-none flex justify-center items-center"
                    href="#"
                  >
                    Pitch Deck
                  </a>
                </li>
                <li>
                  <span className='mx-3'>/</span>
                </li>
                <li>
                  <a
                    className="focus:outline-none flex justify-center items-center web-app"
                    href="#"
                  >
                    Web App
                  </a>
                  <div
                    className="btn-primary coming-soon-btn focus:outline-none flex justify-center items-center text-14 px-5 py-2 mt-2"
                  >
                    Coming Soon
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hero-section">
          <div className="header">
            Enabling DeFi for the traditional world
            <img src={headerBottomBorder} className='mt-5 w-9/12 mx-auto sm:block hidden' />
          </div>
          <div className="sm:text-20 text-18 text-center sub-text">
            Abachi exists to help bring traditional financial institutions and real-world users on chain.
          </div>
          <div className="relative form pt-4">
            <div className="relative">
              <input
                className="email-box w-full py-2 px-3 placeholder-gray-6 text-black focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Please enter your email address"
                // onChange={event => {
                //   setPasswordConfirm(event.target.value)
                // }}
              />
              <button className="btn-primary notify-me-btn focus:outline-none">
                Notify Me
              </button>
            </div>
          </div>
        </div>
        <div className='hero-footer flex sm:justify-end justify-content-center'>
          <img src={githubLogo} className='cursor-pointer' />
          <img src={twitterLogo} className='cursor-pointer' />
          <img src={carboLogo} className='cursor-pointer' />
          <img src={mailLogo} className='cursor-pointer' />
        </div>
      </div>
    </div>
  )
}
