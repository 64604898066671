import React from "react"
import ReactGA from "react-ga";

import SEO from "../components/seo"
import Top from "../components/abachi/top"
import seoImage from "../images/video/ebook.png"

import "../scss/style.scss"
export default function NewLandingPage() {
  const navItemClick = (navItem) => {
    ReactGA.event({
      category: 'NAV_ITEM',
      action: `Clicked ${navItem} Nav Item of Ebook Page`
    });
  }
  return (
    <div className="font-robo abachi">
      <SEO title="Abachi" description="abachi" image={seoImage}
           keywords="abachi" />
      <Top navItemClick={navItemClick} />
    </div>
  )
}
